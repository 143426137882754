import React, { useContext, useState, useEffect } from "react";
import { StateContext } from "../StateContainer";
import axios from "axios";
import { useParams } from "react-router-dom";
import Moment from "react-moment";
import "moment/locale/it";
import { ImParagraphLeft as Visualizza } from "react-icons/im";
import { CgTrash as Cestino } from "react-icons/cg";
import DragAndDrop from "../DragAndDrop/DragAndDrop";
import DocumentiCaricatiMobile from "./Mobile/DocumentiCaricatiMobile";

const DocumentiCarica = () => {
  const { id } = useParams();
  const [selectedFile, setSelectedFile] = useState(null);
  const {
    setLoading,
    setError,
    token,
    aggiornamentoDatiProgetto,
    textOfTheDocument,
    setTextOfTheDocument,
    currentIndexDocument,
    setCurrentIndexDocument,
  } = useContext(StateContext);

  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [isGettingDocument, setIsisGettingDocument] = useState(false);
  const [isDeletingDocument, setIsDeletingDocument] = useState(false);
  const [fileCaricati, setFileCaricati] = useState([]);
  const [statoCaricamentoFile, setStatoCaricamentoFile] = useState(0);

  const [valueDoc, setValueDoc] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  let updateProgress = 0;
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsisGettingDocument(true);

    const arrayCaricamento = {
      charsCount: null,
      code: "temporary-array-uploading-file",
      title: selectedFile.name,
      temporary: true,
      created: {
        date: Date.now(),
        timezone: "Europe/Rome",
        timezone_type: 3,
      },
      tokenCount: null,
      updated: {
        date: Date.now(),
        timezone: "Europe/Rome",
        timezone_type: 3,
      },
    };

    setFileCaricati((prev) => [...prev, arrayCaricamento]);

    setIsUploadingDocument(true);
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PYTHON_ENGINE}/upload_files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          params: {
            projectCode: id,
            title: selectedFile.name,
            token: token,
          },
          onUploadProgress: (progressEvent) => {
            let percentComplete = progressEvent.loaded / progressEvent.total;
            percentComplete = parseInt(percentComplete * 100);
            setStatoCaricamentoFile(percentComplete);
          },
        }
      );
    } catch (error) {
      console.error("sono l'errore", error);
    } finally {
      setIsUploadingDocument(false);
      setIsisGettingDocument(false);
      setFileCaricati((prev) =>
        prev.filter((item) => item.code !== "temporary-array-uploading-file")
      );
      document.getElementById("inputFileDocument").value = null;
      setSelectedFile(null);
    }
  };

  useEffect(() => {
    setLoading(true);
    // Funzione per la chiamata API

    const fetchData = () => {
      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/project/detail`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: {
          projectCode: id,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
            window.location.replace("/login");
          } else {
            setLoading(false);
            if (response.data.response.documents !== fileCaricati) {
              setFileCaricati(response.data.response.documents);
            }
            setError("");
          }
        })
        .catch((err) => setError(err));
    };

    // Esegui l'effetto una sola volta all'inizio
    if (!isUploadingDocument) {
      fetchData();
    } else {
      if (!isGettingDocument) {
        // Esegui l'effetto ogni 5 secondi
        const interval = setInterval(fetchData, 5000);

        // Pulisci l'intervallo quando l'effetto viene rimosso
        return () => clearInterval(interval);
      }
    }
  }, [isUploadingDocument, aggiornamentoDatiProgetto]);

  const EliminaDocumento = (deleteCode, vectorsIds) => {
    setIsDeletingDocument(true);
  
    axios({
      method: "post",
      url: `${process.env.REACT_APP_PYTHON_ENGINE}/delete_document`,
      data: {
        deleteCode: deleteCode,
        projectCode: id,
        token: token,
        vectorsIds: vectorsIds
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setIsDeletingDocument(false);
          if (response.data.status === 200) {
            const index = fileCaricati.findIndex(
              (el) => el.code === response.data.code
            );
            if (index !== -1) {
              fileCaricati.splice(index, 1);
            }
          }
        }
      })
      .catch((err) => setError(err));
  };
  

  const GetDocumentText = (code, index) => {
    setCurrentIndexDocument(index);
    setLoading(true);
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/get-document-text`,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: JSON.stringify({
        projectCode: id,
        code: code,
      }),
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else {
          setTextOfTheDocument(response.data.response.text);
        }
      })
      .catch((err) => setError(true));
  };

  return (
    <div
      className='container position-relative h-100 d-flex flex-column '
      style={{
        width: "auto",
      }}
    >
      <div className='col-auto'>
        <p className='text-muted'>
          Carica qui i tuoi documenti pdf, powerpoint, txt. I documenti devono
          essere di tipo testuale, non vengono acquisiti ad esempio documenti in
          forma di immagine. Puoi caricare documenti fino ad un massimo di 20 MB
        </p>
      </div>

      <div>
        <DragAndDrop
          onDrop={handleFileChange}
          setSelectedFile={setSelectedFile}
          isUploadingDocument={isUploadingDocument}
        />
      </div>

      <div className='col-auto gap-2 d-flex align-items-end justify-content-end'>
        {/* <button className='btn text-white bg-rosso'>Annulla</button> */}
        <button
          className={`btn text-white bg-green-second ${
            isUploadingDocument || !selectedFile ? "disabled" : ""
          }`}
          type='submit'
          onClick={handleFormSubmit}
        >
          Carica
        </button>
      </div>
      <div className='col-auto my-5'>
        {isDeletingDocument ? (
          <div
            className='alert alert-warning d-flex align-items-center gap-2'
            role='alert'
          >
            <div className='spinner-border fs-6' role='status'>
              <span className='sr-only'></span>
            </div>
            Sto eliminando il documento, attendere ...
          </div>
        ) : (
          ""
        )}
        <table className='table rounded d-none d-xl-table'>
          <thead className='bg-secondary text-white'>
            <tr>
              <th scope='col'></th>
              <th scope='col'>Titolo</th>
              <th scope='col'>Data di caricamento</th>
              <th scope='col'>Numero di Caratteri</th>
              <th scope='col'>Azioni</th>
            </tr>
          </thead>
          <tbody>
            {fileCaricati.length === 0 && !isUploadingDocument ? (
              <tr className='text-center'>
                <td colSpan={5} className='py-4'>
                  Nessun documento è stato ancora caricato
                </td>
              </tr>
            ) : (
              fileCaricati.map((document, index) => {
                return (
                  <>
                    <tr key={index}>
                      <th scope='row' className='align-middle'>
                        {index + 1}
                      </th>

                      <td className='align-middle'>{document.title}</td>

                      {document.temporary && isGettingDocument ? (
                        <td className='align-middle text-start' colSpan={1}>
                          <div className='form-outline'>
                            <div className='loading-dots'>
                              <div className='loading-dots--dot dot_file'></div>
                              <div className='loading-dots--dot dot_file'></div>
                              <div className='loading-dots--dot dot_file'></div>
                            </div>
                          </div>
                        </td>
                      ) : (
                        <td className='align-middle'>
                          <Moment format='DD MMMM YYYY, HH:mm' locale='it'>
                            {document.updated.date}
                          </Moment>
                        </td>
                      )}
                      <td className='align-middle'>
                        {document.charsCount !== null
                          ? document.charsCount.toLocaleString("it-IT")
                          : ""}
                      </td>
                      <td className='text-center align-middle'>
                        <div className='d-flex gap-2 justify-content-center align-self-center'>
                          {document.temporary && isGettingDocument ? (
                            ""
                          ) : (
                            <>
                              <button
                                className='btn btn-danger text-white p-0 px-1 fs-5 align-middle'
                                onClick={() => EliminaDocumento(document.code, document.vectorsIds)}
                              >
                                <Cestino></Cestino>
                              </button>
                              <button
                                className='btn bg-green-second text-white p-0 px-2 fs-6 d-flex align-items-center'
                                title='Aggiorna Domanda e risposta'
                                data-bs-toggle='collapse'
                                aria-haspopup='true'
                                aria-expanded='false'
                                aria-controls={`aggiornaDocument-${index}`}
                                href={`#aggiornaDocument-${index}`}
                                onClick={() =>
                                  GetDocumentText(document.code, index)
                                }
                              >
                                <Visualizza></Visualizza>
                              </button>
                            </>
                          )}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5} className='p-0 border-0'>
                        <div
                          id={`aggiornaDocument-${index}`}
                          className={`border-0 ${
                            currentIndexDocument === index
                              ? "collapse show collapsing"
                              : "collapse"
                          }`}
                        >
                          <div className='divRisposta py-2'>
                            <label htmlFor={`inputRisposta-${index}`}>
                              Testo Acquisito
                            </label>
                            <textarea
                              disabled='true'
                              id={`inputRisposta-${index}`}
                              // placeholder={faq.answer}
                              value={textOfTheDocument}
                              onChange={(event) =>
                                setTextOfTheDocument(event.target.value)
                              }
                              className='w-100 p-2 mt-2 rounded inputSitoWeb'
                              style={{ minHeight: "400px" }}
                            />
                            {/* <div className='w-100 d-flex justify-content-end'>
                              <button
                                className='btn text-white bg-green-second my-3'
                                onClick={() =>
                                  AggiornaTestoDocumento(
                                    document.code,
                                    textOfTheDocument
                                  )
                                }
                              >
                                Aggiorna Testo Documento
                              </button>
                            </div> */}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })
            )}
          </tbody>
        </table>
        <div className='d-block d-xl-none'>
          {fileCaricati.length === 0 ? (
            <tr className='text-center'>
              <td colSpan={5} className='py-4'>
                Nessun sito è stato ancora caricato
              </td>
            </tr>
          ) : (
            fileCaricati.map((doc, index) => {
              return (
                <DocumentiCaricatiMobile
                  doc={doc}
                  index={index}
                  EliminaDocumento={EliminaDocumento}
                  GetDocumentText={GetDocumentText}
                ></DocumentiCaricatiMobile>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default DocumentiCarica;
