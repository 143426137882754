import React from "react";
import { FiEdit2 as Matita } from "react-icons/fi";
import { CgTrash as Cestino } from "react-icons/cg";
import { IoRefresh as Refresh } from "react-icons/io5";
import { IoInformationSharp as Information } from "react-icons/io5";
import Info from "./Modals/Info";
import { ImParagraphLeft as Visualizza } from "react-icons/im";
import InfoDocumento from "./Modals/InfoDocumento";
import CollapseVisualizzaTestoDoc from "./Collapses/CollapseVisualizzaTestoDoc";

const DocumentiCaricatiMobile = ({
  doc,
  index,
  EliminaDocumento,
  GetDocumentText,
}) => {
  return (
    <>
      <div
        className='row d-flex align-items-center py-3'
        style={{ borderBottom: "1px black solid" }}
        key={index}
      >
        <div className='col-1 fw-bold'>{index + 1}</div>
        <div className='col-auto font-size-14 '>
          {doc.title.length < 50 ? doc.title : doc.title.substr(0, 46) + " ..."}
        </div>

        {/* Informazioni url del sito */}
        <div className='col w-auto d-flex justify-content-end gap-2'>
          <button
            className='btn btn-success text-white p-0 px-2 fs-5 d-flex align-items-center'
            data-bs-toggle='modal'
            data-bs-target={`#info-${index}`}
          >
            <Information></Information>
          </button>

          {/* Elimina url del sito */}
          <button
            className='btn btn-danger text-white p-0 px-1 fs-5'
            onClick={() => EliminaDocumento(doc.code, doc.vectorsIds)}
            title='Rimuovi pagina sito'
          >
            <Cestino></Cestino>
          </button>
          <button
            className='btn btn-primary text-white p-0 px-2 fs-6 d-flex align-items-center'
            title='Aggiorna Domanda e risposta'
            data-bs-toggle='collapse'
            aria-haspopup='true'
            aria-expanded='false'
            aria-controls={`aggiornaDocument-${index}`}
            href={`#aggiornaDocument-${index}`}
            onClick={() => GetDocumentText(doc.code, index)}
          >
            <Visualizza></Visualizza>
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <InfoDocumento index={index} doc={doc}></InfoDocumento>
        </div>
        <CollapseVisualizzaTestoDoc index={index}></CollapseVisualizzaTestoDoc>
      </div>
    </>
  );
};

export default DocumentiCaricatiMobile;
