import React from "react";
import { FiEdit2 as Matita } from "react-icons/fi";
import { CgTrash as Cestino } from "react-icons/cg";
import { IoRefresh as Refresh } from "react-icons/io5";
import { IoInformationSharp as Information } from "react-icons/io5";
import CollapseModificaTestoUrl from "./Collapses/CollapseModificaTestoUrl";
import Info from "./Modals/Info";

const SitiCrawlatiMobile = ({
  link,
  index,
  GetLinkText,
  AggiornaUrlSito,
  EliminaUrlSito,
  AggiornaTestoLink,
}) => {
  return (
    <React.Fragment key={index}>
      <div
        className='row d-flex align-items-center py-3'
        style={{ borderBottom: "1px black solid" }}
        key={index}
      >
        <div className='col-1 fw-bold'>{index + 1}</div>
        <div className='col-auto font-size-14 '>
          {link.url.length < 50 ? link.url : link.url.substr(0, 46) + " ..."}
        </div>

        {/* Informazioni url del sito */}
        <div className='col w-auto d-flex justify-content-end gap-2'>
          <button
            className='btn btn-success text-white p-0 px-2 fs-5 d-flex align-items-center'
            onClick={() => GetLinkText(link.code, index)}
            data-bs-toggle='modal'
            data-bs-target={`#info-${index}`}
          >
            <Information></Information>
          </button>

          {/* Elimina url del sito */}
          <button
            className='btn btn-danger text-white p-0 px-1 fs-5'
            onClick={() => EliminaUrlSito(link.code, link.vectorsIds)}
            title='Rimuovi pagina sito'
          >
            <Cestino></Cestino>
          </button>
          {/* Nuovo aggiornamento della pagina del sito */}
          <button
            className='btn btn-primary text-white p-0 px-1 fs-5'
            onClick={() => AggiornaUrlSito(link.url, link.code, link.vectorsIds)}
            title='Aggiorna pagina sito'
          >
            <Refresh></Refresh>
          </button>
          {/* Modifica il sito */}
          <button
            className='btn btn-primary text-white p-0 px-2 fs-5 d-flex align-items-center'
            title='Aggiorna Testo Link'
            data-bs-toggle='collapse'
            aria-haspopup='true'
            aria-expanded='false'
            aria-controls={`aggiornaLink-${index}`}
            href={`#aggiornaLink-${index}`}
            onClick={() => GetLinkText(link.code, index)}
          >
            <Matita></Matita>
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <CollapseModificaTestoUrl
            link={link}
            index={index}
            AggiornaTestoLink={AggiornaTestoLink}
            GetLinkText={GetLinkText}
          ></CollapseModificaTestoUrl>
        </div>
      </div>
      <Info
        index={index}
        primoTitolo={"Pagina Web del Sito"}
        secondoTitolo={"Data Ultima Scansione"}
        first_element={link.url}
        second_element={link.updated.date}
        third_element={link.charsCount}
      ></Info>
    </React.Fragment>
  );
};

export default SitiCrawlatiMobile;
