import React, { useContext, useState } from "react";
import { StateContext } from "../../../StateContainer";

const CollapseModificaTestoUrl = ({ link, index, AggiornaTestoLink }) => {
  const { textOfTheLink, setTextOfTheLink, currentIndex, setCurrentIndex } =
    useContext(StateContext);
  return (
    <div
      id={`aggiornaLink-${index}`}
      className={`collapsing ${
        currentIndex === index ? "collapse show" : "collapse"
      }`}
    >
      <div className='divRisposta py-2'>
        <label htmlFor={`inputRisposta-${index}`}>Testo Acquisito</label>
        <textarea
          id={`inputRisposta-${index}`}
          value={textOfTheLink}
          onChange={(event) => setTextOfTheLink(event.target.value)}
          className='w-100 p-2 mt-2 rounded inputSitoWeb'
          style={{ minHeight: "400px" }}
        />
        <div className='w-100 d-flex justify-content-end'>
          <button
            className='btn text-white bg-green-second my-3'
            onClick={() =>
              AggiornaTestoLink(link.code, textOfTheLink, link.url, link.vectorsIds)
            }
          >
            Aggiorna Testo Link
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollapseModificaTestoUrl;
