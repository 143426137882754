import React from "react";
import { FiEdit2 as Matita } from "react-icons/fi";
import { CgTrash as Cestino } from "react-icons/cg";
import { IoRefresh as Refresh } from "react-icons/io5";
import { IoInformationSharp as Information } from "react-icons/io5";
import Info from "./Modals/Info";
import { ImParagraphLeft as Visualizza } from "react-icons/im";
import CollapseVisualizzaTestoDoc from "./Collapses/CollapseVisualizzaTestoDoc";
import CollapseModificaFaq from "./Collapses/CollapseModificaFaq";

const FaqCaricatiMobile = ({
  faq,
  index,
  EliminaFaq,
  AggiornaFaq,
  RemoveAzioniFaqUpdate,
  UpdateAzioniUpdate,
  AggiungiAzioniFaqUpdate,
}) => {
  return (
    <>
      <div
        className='row d-flex align-items-center py-3'
        style={{ borderBottom: "1px black solid" }}
        key={index}
      >
        <div className='col-1 fw-bold'>{index + 1}</div>
        <div className='col-auto font-size-14 '>
          {faq.question.length < 50
            ? faq.question
            : faq.question.substr(0, 46) + " ..."}
        </div>

        {/* Informazioni url del sito */}
        <div className='col w-auto d-flex justify-content-end gap-2'>
          <button
            className='btn btn-success text-white p-0 px-2 fs-5 d-flex align-items-center'
            data-bs-toggle='modal'
            data-bs-target={`#info-${index}`}
          >
            <Information></Information>
          </button>

          {/* Elimina url del sito */}
          <button
            className='btn btn-danger text-white p-0 px-1 fs-5'
            onClick={() => EliminaFaq(faq.code, faq.vectorsIds)}
            title='Rimuovi pagina sito'
          >
            <Cestino></Cestino>
          </button>
          <button
            className='btn btn-primary text-white p-0 px-2 fs-6 d-flex align-items-center'
            title='Aggiorna Domanda e risposta'
            data-bs-toggle='collapse'
            aria-haspopup='true'
            aria-expanded='false'
            aria-controls={`aggiornafaq-${index}`}
            href={`#aggiornaFaq-${index}`}
            // onClick={() => AggiornaFaq(faq.code, index)}
          >
            <Matita></Matita>
          </button>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <Info
            index={index}
            faq={faq}
            primoTitolo={"Risposta"}
            first_element={faq.answer}
            secondoTitolo={"Data di aggiornamento"}
            second_element={faq.updated.date}
            third_element={faq.charsCount}
          ></Info>
        </div>
        <CollapseModificaFaq
          indexFaq={index}
          RemoveAzioniFaqUpdate={RemoveAzioniFaqUpdate}
          UpdateAzioniUpdate={UpdateAzioniUpdate}
          faq={faq}
          AggiornaFaq={AggiornaFaq}
          AggiungiAzioniFaqUpdate={AggiungiAzioniFaqUpdate}
        ></CollapseModificaFaq>
      </div>
    </>
  );
};

export default FaqCaricatiMobile;
