import React, { useState, useContext, useEffect } from "react";
import { StateContext } from "../StateContainer";
import { useParams } from "react-router-dom";
import { BsPlusCircleFill as Aggiungi } from "react-icons/bs";
import { FiEdit2 as Matita } from "react-icons/fi";
import { CgTrash as Cestino } from "react-icons/cg";
import { IoRefresh as Refresh } from "react-icons/io5";
import Moment from "react-moment";
import "moment/locale/it";
import axios from "axios";
import ModalNotificationError from "../Modals/ModalNotificationError";
import TestiLiberiCaricatiMobile from "./Mobile/TestiLiberiCaricatiMobile";
import ModalSuccess from "../Modals/ModalSuccess";
import ModalError from "../Modals/ModalError";
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import Editor from '@monaco-editor/react';

const NodiConversazione = ({ dettagliProgetto }) => {
  const {
    setDominioDelSito,
    setLoading,
    aggiornamentoDatiProgetto,
    setError,
    token,
  } = useContext(StateContext);

  const { id } = useParams();
  const [conversationalJson, setConversationalJson] = useState("");
  const [aggiornatoNodiConversazione, setAggiornatoNodiConversazione] = useState(false);
  const [erroreNodiConversazione, setErroreNodiConversazione] = useState(false);

  const AggiornaNodiConversazione = () => {
    setAggiornatoNodiConversazione(false)
    setErroreNodiConversazione(false)
    axios({
      method: "post",
      url: `${process.env.REACT_APP_BASE_URL}/project/update`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token,
      },
      data: {
        projectCode: id,
        conversationalJSON: JSON.stringify(conversationalJson),
      },
    })
      .then((response) => {
        if (response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("tokenExpiration");
          window.location.replace("/login");
        } else if (response.status === 200) {
          setErroreNodiConversazione(false)
          setAggiornatoNodiConversazione(true)
          setEditorValue(response.data.response.projects.conversationalJSON)
            try {
            const parsedJson = JSON.parse(response.data.response.projects.conversationalJSON); // Validazione del JSON
            setConversationalJson(parsedJson); // Aggiorna il JSON nello stato genitore
            } catch (e) {
            console.warn('Invalid JSON:', e.message); // Mostra un avviso in caso di errore
            }
        //   setConversationalJson(response.data.response.projects.conversationalJSON)
          console.log(response.data.response.projects.conversationalJSON)
        }
      })
      .catch((err) => {
        setErroreNodiConversazione(true)
        setAggiornatoNodiConversazione(false)
      });
  };

  

  useEffect(() => {
    setDominioDelSito("");
    setLoading(true);

      axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/project/detail`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: {
          projectCode: id,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("tokenExpiration");
            window.location.replace("/login");
          } else {
            setLoading(false);
            setEditorValue(response.data.response.conversationalJSON)
            try {
            const parsedJson = JSON.parse(response.data.response.conversationalJSON); // Validazione del JSON
            setConversationalJson(parsedJson); // Aggiorna il JSON nello stato genitore
            } catch (e) {
            console.warn('Invalid JSON:', e.message); // Mostra un avviso in caso di errore
            }
            console.log("conv", response.data.response.conversationalJSON)
            setError("");
          }
        })
        .catch((err) => setError(err));
    

  }, []);


//   Monaco 
const [editorValue, setEditorValue] = useState(
    JSON.stringify(conversationalJson, null, 2) // Formatta il JSON con indentazione
  );
  

  // Aggiorna lo stato quando il JSON cambia
useEffect(() => {
    setEditorValue(JSON.stringify(conversationalJson, null, 2)); // Formattazione con indentazione
  }, [conversationalJson]);
  
  // Gestore delle modifiche nell'editor
  const handleEditorChange = (value) => {
    setEditorValue(value);
    try {
      const parsedJson = JSON.parse(value); // Validazione del JSON
      setConversationalJson(parsedJson);   // Aggiorna il JSON nello stato principale
    } catch (e) {
      console.warn('Invalid JSON:', e.message); // Mostra un avviso in caso di errore
    }
  };

  return (
    <>
      <div id='modifica-testo-libero'></div>
      <div className='container position-relative h-100 d-flex flex-column card_content'>
        <div className="row">
            <div className="col-12">
            <label htmlFor="inputTesto">Conversazione a nodi</label>
            {/* <Editor
        height="100%"
        defaultLanguage="json" // Configura il linguaggio come JSON
        theme="vs-dark" // Scegli un tema (es. vs-light, vs-dark)
        value={editorValue} // Il valore iniziale da mostrare nell'editor
        onChange={handleEditorChange} // Callback per le modifiche
        options={{
          minimap: { enabled: false }, // Disabilita la minimappa
          automaticLayout: true, // Adatta automaticamente il layout
          scrollBeyondLastLine: false, // Evita lo scroll oltre l'ultima riga
        }}
      /> */}

    <Editor
    height="80vh"
    maxHeight="50vh"
    theme="vs-dark"
    value={editorValue}
    onChange={handleEditorChange}
    defaultLanguage="json"
    options={{
        minimap: { enabled: false },  // Nasconde la minimappa
        formatOnPaste: true,         // Formatta automaticamente
        formatOnType: true           // Suggerimento formattazione
    }}
    />

            {/* <textarea
                style={{ height: "auto", minHeight: "300px" }}
                id={`inputTesto`}
                placeholder='Scrivi o copia incolla il testo'
                className='w-100 p-2 my-2 rounded inputTesto'
                value={conversationalJson}
                onChange={e => setConversationalJson(e.target.value)}
              /> */}
            </div>
        </div>

        <div className='col-12 d-flex justify-content-end'>
            <div>
              <a
                className={`btn text-white bg-green-second mt-3`}
                onClick={AggiornaNodiConversazione}
              >
                Aggiorna
              </a>
            </div>
          </div>
          

        
      </div>

      {aggiornatoNodiConversazione === true ? (
                  <ModalSuccess
                    title='Conversazione aggiornata'
                    message='Il modello di conversazione è stato aggiornato correttamente'
                    FunctionRemoveModal={setAggiornatoNodiConversazione}
                  />)
            
        : ""}

        {erroreNodiConversazione === true ? (
                  <ModalError
                  />)
            
        : ""}

    </>
  );
};

export default NodiConversazione;
